export default {
  heading: 'Passion One, sans-serif',
  body: 'Open Sans, serif',
  monospace: 'Open Sans, monospace',
  display: 'Vujahday Script, cursive',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Passion One',
    'Vujahday Script'
    // 'Nunito:100,200,300,400,500,600,700,800,900',
    // 'Montserrat:100,200,300,400,500,600,700,800,900',
    // 'Italiana',
    // 'Jura',
    // 'Barlow:100,200,300,400,500,600,700,800,900',
    // 'Sriracha',
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
