import noDataContainer from '../../../../../gatsby-theme-minimal/src/gatsby-plugin-theme-ui/Reuseables/NoDataContainer'
const variantName = 'teamMenu'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '0rem',
      display: ['', '', '', 'flex']
    },

    // ?============================
    // ?========  Filters   ========
    // ?============================

    filters: {
      display: 'flex',
      padding: ['0rem', '', '', '0.5rem'],
      flexDirection: ['', '', '', 'column'],
      minWidth: '250px',
      borderRight: ['', '', '', '1px solid'],
      marginRight: ['', '', '', '1rem'],
      flexWrap: 'wrap'
    },
    filtersTitle: {
      width: '100%',
      padding: '0.5rem',
      marginBottom: '0.5rem'
    },

    filterLabel: {
      marginBottom: '0.25rem',
      padding: '0.25rem',
      fontSize: '0.8rem'
    },

    orderByContainer: {
      minWidth: '100px',
      margin: '0.5rem'
    },
    searchContainer: {
      flexGrow: ['1', '', '', '0'],
      margin: ['0.5rem'],
      svg: {
        position: 'absolute',
        right: '0.75rem',
        top: '50%',
        transform: 'translateY(-50%)'
      }
    },
    searchInputContainer: {
      position: 'relative'
    },

    sortByContainer: {
      margin: '0.5rem'
    },

    // ?============================
    // ?======  Article Boxes ======
    // ?============================

    blogsContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    boxesHeading: {
      textTransform: 'uppercase',
      textAlign: 'center',
      width: 'fit-content',
      padingBottom: '0.5rem',
      margin: ['2rem auto 2rem', '', '', '2rem auto 2rem']
    },

    boxesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'stretch',
      width: '100%',
      '> div:nth-of-type(even)': {
        backgroundColor: '#2d2d2d',

        '.imageContainer': {
          order: ['', '', '3']
        }
      }
    },
    box: {
      width: ['100%'],
      padding: '0rem',
      position: 'relative',
      overflow: 'hidden',
      flexGrow: '1',
      padding: '3rem 1rem',
      minHeight: ['', '', '', '90vh'],
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'rgb(27 16 36 / 90%)',
      '.boxContentContainer': {
        display: 'flex',
        flexDirection: ['column', '', 'row'],
        height: '100%'
      }
    },

    // ?============================
    // ?======  Image Content =======
    // ?============================

    imageContainer: {
      width: ['100%', '', '40%'],
      position: 'relative',
      overflow: 'hidden'
    },
    imageFill: {
      paddingBottom: '100%'
    },
    image: {
      transition: 'all ease-in-out 0.5s',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'top'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================
    textContent: {
      padding: ['1rem', '', '2rem 3rem', '2rem 7rem'],
      fontSize: '1rem',
      transition: 'all ease-in-out 0.5s',
      backgroundColor: 'transparent',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      color: 'white'
    },
    // name: {
    //   fontSize:'1.5em',
    //   marginBottom: '1rem',
    // },
    title: {
      fontSize: ['2em', '2.5em', '3.5em', '5em'],
      marginBottom: '1rem',
      color: 'white',
      lineHeight: '1.5'
    },
    subtitle: {
      fontSize: '2em',
      fontSize: ['1.1em', '1.2em', '1.3em', '1.5em'],
      marginBottom: '1rem',
      opacity: '0.7',
      fontWeight: '400',
      color: 'lightgrey',
      lineHeight: '1.5'
    },
    text: {
      fontSize: '0.9em',
      marginBottom: '1rem',
      flexGrow: '1',
      lineHeight: '1.25',
      display: 'none',
      color: 'white'
    },

    bodyContainer: {
      color: 'white',
      p: {
        color: 'white'
      },
      ul: {
        margin: '2rem 0rem',
        li: {
          fontSize: '1.25em'
        }
      }
    },

    timeContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      borderTop: '1px solid',
      opacity: '0.6',
      padding: '0.5rem 0rem 0rem',
      margin: 'auto 0rem 0rem',
      display: 'none',
      svg: {
        marginRight: '0.5rem'
      }
    },

    readingTime: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    createdAt: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaContainer: {
      a: {
        display: 'flex',
        variant: 'buttons.primary'
      }
    },

    // ?=============================
    // ?====  No Data Container  ====
    // ?=============================

    ...noDataContainer
  }
}
