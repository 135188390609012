export default {
  primary: {
    backgroundColor: 'transparent',
    color: 'light',
    border: 'solid 3px',
    borderColor: 'white',
    borderRadius: '0px',
    textTransform: 'uppercase',
    fontSize: ['1.1rem', '', '1.25rem'],
    fontFamily: 'heading',
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      backgroundColor: 'black',
      borderColor: 'black'
    }
  },
  secondary: {
    backgroundColor: 'black',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'black',
    borderRadius: '0px',
    textTransform: 'uppercase',
    fontSize: ['1.1rem', '', '1.25rem'],
    fontFamily: 'heading',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white',
      borderColor: 'primary'
    }
  }
}
